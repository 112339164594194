import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function RecipeSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [filters, setFilters] = useState({
    category: 'All',
    cuisine: 'All',
    difficulty: 'All'
  });

  const categories = ['All', 'Breakfast', 'Lunch', 'Dinner', 'Dessert'];
  const cuisines = ['All', 'Italian', 'Mexican', 'Chinese', 'Indian', 'American'];
  const difficulties = ['All', 'Easy', 'Medium', 'Hard'];

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await axios.get('/api/recipes/search', {
          params: {
            term: searchTerm,
            category: filters.category !== 'All' ? filters.category : '',
            cuisine: filters.cuisine !== 'All' ? filters.cuisine : '',
            difficulty: filters.difficulty !== 'All' ? filters.difficulty : ''
          }
        });
        setRecipes(response.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    };

    fetchRecipes();
  }, [searchTerm, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  return (
    <div className="recipe-search">
      <h1>Search Recipes</h1>
      <input 
        type="text" 
        placeholder="Search recipes..." 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      
      <div className="filters">
        <select name="category" value={filters.category} onChange={handleFilterChange}>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        
        <select name="cuisine" value={filters.cuisine} onChange={handleFilterChange}>
          {cuisines.map(cuisine => (
            <option key={cuisine} value={cuisine}>{cuisine}</option>
          ))}
        </select>
        
        <select name="difficulty" value={filters.difficulty} onChange={handleFilterChange}>
          {difficulties.map(difficulty => (
            <option key={difficulty} value={difficulty}>{difficulty}</option>
          ))}
        </select>
      </div>
      
      <div className="recipe-grid">
        {recipes.map(recipe => (
          <div key={recipe._id} className="recipe-card">
            <img src={recipe.image} alt={recipe.name} />
            <h3>{recipe.name}</h3>
            <p>{recipe.description}</p>
            <p>Category: {recipe.category}</p>
            <p>Cuisine: {recipe.cuisine}</p>
            <p>Difficulty: {recipe.difficulty}</p>
            <Link to={`/recipe/${recipe._id}`}>View Recipe</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecipeSearch;