import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Home() {
  const [featuredRecipes, setFeaturedRecipes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeaturedRecipes = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/recipes/featured');
        setFeaturedRecipes(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching featured recipes:', error);
        setError('Failed to fetch featured recipes. Please try again later.');
        setLoading(false);
      }
    };

    fetchFeaturedRecipes();
  }, []);

  const categories = ['All', 'Breakfast', 'Lunch', 'Dinner', 'Dessert'];
  const cuisines = ['All', 'Italian', 'Mexican', 'Chinese', 'Indian', 'American'];

  const filteredRecipes = featuredRecipes.filter(recipe => 
    (selectedCategory === 'All' || recipe.category === selectedCategory) &&
    (selectedCuisine === 'All' || recipe.cuisine === selectedCuisine)
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="home">
      <header className="hero">
        <h1>Welcome to Our Recipe Website</h1>
        <p>Discover delicious recipes from around the world!</p>
        <Link to="/search" className="cta-button">Explore All Recipes</Link>
      </header>
      
      <section className="featured-recipes">
        <h2>Featured Recipes</h2>
        <div className="filters">
          <select 
            value={selectedCategory} 
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          <select 
            value={selectedCuisine} 
            onChange={(e) => setSelectedCuisine(e.target.value)}
          >
            {cuisines.map(cuisine => (
              <option key={cuisine} value={cuisine}>{cuisine}</option>
            ))}
          </select>
        </div>

        <div className="recipe-grid">
          {filteredRecipes.length > 0 ? (
            filteredRecipes.map(recipe => (
              <div key={recipe._id} className="recipe-card">
                <img src={recipe.image} alt={recipe.name} />
                <h3>{recipe.name}</h3>
                <p>{recipe.description}</p>
                <p>Category: {recipe.category}</p>
                <p>Cuisine: {recipe.cuisine}</p>
                <Link to={`/recipe/${recipe._id}`} className="view-recipe-button">View Recipe</Link>
              </div>
            ))
          ) : (
            <p>No recipes found for the selected filters.</p>
          )}
        </div>
      </section>
      
      <section className="about-us">
        <h2>About Us</h2>
        <p>We are passionate about bringing you the best recipes from around the world. Our team of expert chefs and food enthusiasts curate and test each recipe to ensure the best culinary experience for you.</p>
        <Link to="/about" className="learn-more-button">Learn More</Link>
      </section>

      <section className="newsletter">
        <h2>Stay Updated</h2>
        <p>Subscribe to our newsletter for the latest recipes and cooking tips.</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <input type="email" placeholder="Enter your email" required />
          <button type="submit">Subscribe</button>
        </form>
      </section>
    </div>
  );
}

export default Home;